import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  //   .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "ua", // язык по умолчанию
    fallbackLng: "ua",
    returnObjects: true,
    detection: {
      order: ["path", "navigator"],
      lookupFromPathIndex: 0, // Вытягиваем язык из первой части URL
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          headerNav1: "Home",
          headerNav2: "Services",
          headerNav3: "Portfolio",
          headerNav4: "Contacts",
          headerBtn: "Get an offer",
          mainSmallTitle: "Full Range of IT Services",
          MainTitle: "Artificial Intelligence for Your Business",
          MainBtn1: "Mobile Apps",
          MainBtn2: "Online-Stores",

          AITitle: "AI Services",
          AIName1: "Salesman Bot",
          AIText11: "Replaces 90% of the functions of real managers;",
          AIText12: "Tells information about a product or service;",
          AIText13: " Processing orders and transactions via chat;",
          AIText14: "Analyzing customer needs and proposing appropriate solutions;",
          AIText15: "Monitoring deals and notifying about possible discounts or promotions;",
          AIText16: "Integrating with CRM to track and manage the customer base;",
          AIText17: "Integration with any APIs;",

          AIName2: "Guru Bot",
          AIText21: "Learns based on your documentation, correspondence, and other materials;",
          AIText22:
            "Conducts in-depth analysis of large data sets, categorizes information, and provides summary reports;",
          AIText23: "Processes any files and images",
          AIText24: "Answering frequently asked questions and provides technical support and to solve common problems;",
          AIText25: "Help with product or service setup and configuration;",
          AIText26: "Training users on how to use the product via chat;",
          AIText27: "Asking questions and validating answers from customers;",

          AIName3: "Voyager Bot",
          AIText31: "Contacts people via messengers, conducts dialogues, collects necessary data, etc;",
          AIText32: "Sends SMS, emails, analyzes each client's data, and increases conversion for additional sales;",
          AIText33: "Surveys and promotions via chatbot;",
          AIText34: "Understands the needs of the target audience;",
          AIText35: "Lead generation;",
          AIText36: "Support in promotions and raffles;",
          AIText37: "Opened for any other ideas and modifications;",

          MarketingTitle: "Marketing Services",
          MarketingName: "is a modern tool for marketing, increasing sales, and boosting profits.",
          MarketingDescription:
            "We create profitable sales channels and effective advertising. Rapid launch of your product or service sales. From landing page creation, ad creatives production, campaign setup, analytics, and payments — to acquiring your first customers — takes just 3–5 days!",

          ServicesTitle: "IT Services",
          ServicesName1: "Mobile App",
          ServicesDescription1:
            "A mobile app is a comprehensive solution for any task. It is the best way to delegate about 40% of the business owner's routine actions.",
          ServicesName2: "Online-Store",
          ServicesDescription2:
            "An online store is a platform for selling your company's products. In today's digital world, it is the best solution to increase sales and build credibility.",
          ServicesName3: "Landing",
          ServicesDescription3:
            "A landing page is a one-page website designed for marketing or advertising campaigns aimed at attracting visitors and converting them into customers.",
          ServicesName4: "Multipage Website",
          ServicesDescription4:
            "A multipage website is a resource used to inform customers, partners, and the public about the company's activities, products, services, mission, values, and more.",
          ServicesButton: "Order",

          PortfolioTitle: "Our Portfolio",

          TLTitle: "Solution Proccess",

          TLName1: "01. Strategy & Design",
          TLDescription1: "Analyze the client's industry and define UI/UX design.",
          TLList1: "Market Research.",
          TLList2: "Content Search.",
          TLList3: "Design Mockups.",

          TLName2: "02. Development",
          TLDescription2: "Creating the right product using state-of-the-art technology.",
          TLList4: "Design Implementation.",
          TLList5: "Programming.",
          TLList6: "Testing & Fixing.",

          TLName3: "03. Release & Marketing",
          TLDescription3: "Analyze the client's industry, competitors, and target audience",
          TLList7: "Product Release.",
          TLList8: "Marketing Strategy Launch.",
          TLList9: "Enjoy The Result.",

          FooterPhone: "Phones",
          FooterEmail: "Email",
          FooterOffice: "Office",
          FooterOfficeItem: "ul. Boychuk Mykhaila, 41-B, office. 2018",
          FooterSocials: "Socials",
          FooterButton: "Get in touch",
          FooterCopyright: "All Rights Reserved.",

          ModalTitle: "Leave a request and our manager will contact you!",
          ModalText1: "How is your name?",
          ModalText2: "Your e-mail?",
          ModalText3: "Your phone number?",
          ModalText4: "Tell us about your idea :)",
          ModalButton: "Submit a request",
        },
      },
      ua: {
        translation: {
          headerNav1: "Головна",
          headerNav2: "Послуги",
          headerNav3: "Портфоліо",
          headerNav4: "Контакти",
          headerBtn: "Замовити",
          mainSmallTitle: "Повний Спектр ІТ-Послуг",
          MainTitle: "Штучний Інтелект Для Вашого Бізнесу",
          MainBtn1: "Моб. Застосунки",
          MainBtn2: "Інтернет-Магазини",

          AITitle: "ШІ Послуги",
          AIName1: "ШІ Продавець",
          AIText11: "Замінює 90% функцій реальних менеджерів;",
          AIText12: "Розповідає інформацію про товар або послугу;",
          AIText13: "Обробляє замовлення і транзакції через чат;",
          AIText14: "Аналізує потреби клієнтів та пропонує відповідні рішення;",
          AIText15: "Моніторинг угод та повідомлення про можливі знижки або акції;",
          AIText16: "Інтеграція з CRM для відстеження та управління клієнтською базою;",
          AIText17: "Інтеграція з будь-якими API;",

          AIName2: "ШІ Гуру",
          AIText21: "Навчається на основі вашої документації, листування та інших матеріалів;",
          AIText22: "Проводить глибокий аналіз великих масивів даних, класифікує інформацію та надає зведені звіти;",
          AIText23: "Обробляє будь-які файли та зображення;",
          AIText24: "Відповідає на поширені запитання та надає технічну підтримку для вирішення поширених проблем;",
          AIText25: "Допомагає з налаштуванням та конфігурацією продукту або послуги;",
          AIText26: "Навчає користуватися продуктом через чат;",
          AIText27: "Задає запитання та перевіряє відповіді користувачів;",

          AIName3: "ШІ Voyager",
          AIText31: "Контактує з людьми через месенджери, веде діалоги, збирає необхідні дані тощо;",
          AIText32:
            "Надсилає SMS, електронні листи, аналізує дані кожного клієнта та підвищує конверсію для додаткових продажів;",
          AIText33: "Проводить опитування та акції через чат-бота;",
          AIText34: "Розуміє потреби цільової аудиторії;",
          AIText35: "Лідогенерація;",
          AIText36: "Підтримка в акціях та розіграшах;",
          AIText37: "Відкритий до будь-яких інших ідей та модифікацій;",

          MarketingTitle: "Маркетингові Послуги",
          MarketingName: "це сучасний інструмент маркетингу, збільшення продажів і прибутку.",
          MarketingDescription:
            "Ми створюємо вигідні канали збуту та ефективну рекламу. Швидкий запуск продажів вашого продукту або послуги. Від створення лендінгу, розробки рекламних креативів, налаштування кампанії, аналітики та платежів до отримання перших клієнтів - всього за 3-5 днів!",

          ServicesTitle: "ІТ-Послуги",
          ServicesName1: "Мобільний додаток",
          ServicesDescription1:
            "Мобільний додаток - це комплексне рішення для будь-яких завдань. Це найкращий спосіб делегувати близько 40% рутинних дій власника бізнесу.",
          ServicesName2: "Інтернет-магазин",
          ServicesDescription2:
            "Інтернет-магазин - це платформа для продажу товарів вашої компанії. У сучасному цифровому світі це найкраще рішення для збільшення продажів і формування довіри.",
          ServicesName3: "Лендінг",
          ServicesDescription3:
            "Лендінг - це односторінковий веб-сайт, призначений для проведення маркетингових або рекламних кампаній, спрямованих на залучення відвідувачів і перетворення їх на клієнтів.",
          ServicesName4: "Багатосторінковий сайт",
          ServicesDescription4:
            "Багатосторінковий сайт - це ресурс, який використовується для інформування клієнтів, партнерів та громадськості про діяльність компанії, продукти, послуги, місію, цінності та інше.",
          ServicesButton: "Замовити",

          PortfolioTitle: "Наше Портфоліо",

          TLTitle: "Процес Розробки",

          TLName1: "01. Стратегія & Дизайн",
          TLDescription1: "Аналізуємо галузь клієнта та розробляємо UI/UX дизайн.",
          TLList1: "Дослідження ринку.",
          TLList2: "Пошук контенту.",
          TLList3: "Макети дизайну.",

          TLName2: "02. Розробка",
          TLDescription2: "Створюємо програмний продукт з використанням найсучасніших технологій.",
          TLList4: "Реалізація дизайну.",
          TLList5: "Програмування.",
          TLList6: "Тестування та виправлення.",

          TLName3: "03. Запуск & Маркетинг",
          TLDescription3: "Завантажуємо продукт та починаємо його рекламу",
          TLList7: "Випуск продукту.",
          TLList8: "Запуск маркетингової стратегії.",
          TLList9: "Насолода Результатом.",

          FooterPhone: "Телефони",
          FooterEmail: "Ел. пошта",
          FooterOffice: "Офіс",
          FooterOfficeItem: "Вул. Бойчука Михайла, 41-Б, офіс. 2018",
          FooterSocials: "Соц. мережі",
          FooterButton: "Написати нам",
          FooterCopyright: "Всі права захищені.",

          ModalTitle: "Залиште заявку, і наш менеджер зв'яжеться з вами!",
          ModalText1: "Як до вас звертатися?",
          ModalText2: "Ваша Електронна Пошта?",
          ModalText3: "Ваш телефон?",
          ModalText4: "Роскажіть про свою ідею :)",
          ModalButton: "Відправити заявку",
        },
      },
    },
  });

export default i18n;
