import { useTranslation } from "react-i18next";
import "../styles/AIServices.scss";
import { useEffect, useState } from "react";

const AIServices = () => {
  const { t } = useTranslation();
  const [showHorizontal, setShowHorizontal] = useState(false);
  const [showAllHorizontal, setShowAllHorizontal] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1440) {
      setShowHorizontal(false);
      setShowAllHorizontal(false);
    } else if (width <= 1440 && width > 1250) {
      setShowHorizontal(true);
      setShowAllHorizontal(false);
    } else if (width <= 1250 && width > 650) {
      setShowHorizontal(true);
      setShowAllHorizontal(true);
    } else if (width < 650) {
      setShowHorizontal(true);
      setShowAllHorizontal(true);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="AIServices">
      <h2 className="AIServicesTitle">{t("AITitle") as string}</h2>
      <div className="AIServicesContent">
        {showAllHorizontal === true ? (
          <div className="AIServicesItem">
            <div className="AIServicesItemTitle">
              <h4>{t("AIName1") as string}</h4>
              <div className="AIServicesItemImageContainer">
                <img src={process.env.PUBLIC_URL + "shopping-basket.svg"} alt="" className="ServicesImage" />
              </div>
            </div>
            <div className="AIServicesContentLine">
              <ul>
                <li className="AIServicesItemText">{t("AIText11") as string}</li>
                <li className="AIServicesItemText">{t("AIText12") as string}</li>
                <li className="AIServicesItemText">{t("AIText13") as string}</li>
                <li className="AIServicesItemText">{t("AIText14") as string}</li>
                <li className="AIServicesItemText">{t("AIText15") as string}</li>
                <li className="AIServicesItemText">{t("AIText16") as string}</li>
                <li className="AIServicesItemText">{t("AIText17") as string}</li>
              </ul>
              <img src={process.env.PUBLIC_URL + "BotUA1.webp"} alt="" className="AIImage" />
            </div>
          </div>
        ) : (
          <div className="AIServicesItem">
            <div className="AIServicesItemTitle">
              <h4>{t("AIName1") as string}</h4>
              <div className="AIServicesItemImageContainer">
                <img src={process.env.PUBLIC_URL + "shopping-basket.svg"} alt="" className="ServicesImage" />
              </div>
            </div>
            <ul>
              <li className="AIServicesItemText">{t("AIText11") as string}</li>
              <li className="AIServicesItemText">{t("AIText12") as string}</li>
              <li className="AIServicesItemText">{t("AIText13") as string}</li>
              <li className="AIServicesItemText">{t("AIText14") as string}</li>
              <li className="AIServicesItemText">{t("AIText15") as string}</li>
              <li className="AIServicesItemText">{t("AIText16") as string}</li>
              <li className="AIServicesItemText">{t("AIText17") as string}</li>
            </ul>
            <img src={process.env.PUBLIC_URL + "BotUA1.webp"} alt="" className="AIImage" />
          </div>
        )}
        {showAllHorizontal === true ? (
          <div className="AIServicesItem">
            <div className="AIServicesItemTitle">
              <h4>{t("AIName2") as string}</h4>
              <div className="AIServicesItemImageContainer">
                <img src={process.env.PUBLIC_URL + "book.svg"} alt="" className="ServicesImage" />
              </div>
            </div>
            <div className="AIServicesContentLine">
              <ul>
                <li className="AIServicesItemText">{t("AIText21") as string}</li>
                <li className="AIServicesItemText">{t("AIText22") as string}</li>
                <li className="AIServicesItemText">{t("AIText23") as string}</li>
                <li className="AIServicesItemText">{t("AIText24") as string}</li>
                <li className="AIServicesItemText">{t("AIText25") as string}</li>
                <li className="AIServicesItemText">{t("AIText26") as string}</li>
                <li className="AIServicesItemText">{t("AIText27") as string}</li>
              </ul>
              <img src={process.env.PUBLIC_URL + "BotUA2.webp"} alt="" className="AIImage" />
            </div>
          </div>
        ) : (
          <div className="AIServicesItem">
            <div className="AIServicesItemTitle">
              <h4>{t("AIName2") as string}</h4>
              <div className="AIServicesItemImageContainer">
                <img src={process.env.PUBLIC_URL + "book.svg"} alt="" className="ServicesImage" />
              </div>
            </div>
            <ul>
              <li className="AIServicesItemText">{t("AIText21") as string}</li>
              <li className="AIServicesItemText">{t("AIText22") as string}</li>
              <li className="AIServicesItemText">{t("AIText23") as string}</li>
              <li className="AIServicesItemText">{t("AIText24") as string}</li>
              <li className="AIServicesItemText">{t("AIText25") as string}</li>
              <li className="AIServicesItemText">{t("AIText26") as string}</li>
              <li className="AIServicesItemText">{t("AIText27") as string}</li>
            </ul>
            <img src={process.env.PUBLIC_URL + "BotUA2.webp"} alt="" className="AIImage" />
          </div>
        )}

        {showHorizontal === true ? (
          <div className="itemLong">
            <div className="AIServicesItemTitle">
              <h4>{t("AIName3") as string}</h4>
              <div className="AIServicesItemImageContainer">
                <img src={process.env.PUBLIC_URL + "communicate.svg"} alt="" className="ServicesImage" />
              </div>
            </div>
            <div className="AIServicesContentLine">
              <ul>
                <li className="AIServicesItemText">{t("AIText31") as string}</li>
                <li className="AIServicesItemText">{t("AIText32") as string}</li>
                <li className="AIServicesItemText">{t("AIText33") as string}</li>
                <li className="AIServicesItemText">{t("AIText34") as string}</li>
                <li className="AIServicesItemText">{t("AIText35") as string}</li>
                <li className="AIServicesItemText">{t("AIText36") as string}</li>
                <li className="AIServicesItemText">{t("AIText37") as string}</li>
              </ul>
              <img src={process.env.PUBLIC_URL + "BotUA3.webp"} alt="" className="AIImage" />
            </div>
          </div>
        ) : (
          <div className="AIServicesItem">
            <div className="AIServicesItemTitle">
              <h4>{t("AIName3") as string}</h4>
              <div className="AIServicesItemImageContainer">
                <img src={process.env.PUBLIC_URL + "communicate.svg"} alt="" className="ServicesImage" />
              </div>
            </div>
            <ul>
              <li className="AIServicesItemText">{t("AIText31") as string}</li>
              <li className="AIServicesItemText">{t("AIText32") as string}</li>
              <li className="AIServicesItemText">{t("AIText33") as string}</li>
              <li className="AIServicesItemText">{t("AIText34") as string}</li>
              <li className="AIServicesItemText">{t("AIText35") as string}</li>
              <li className="AIServicesItemText">{t("AIText36") as string}</li>
              <li className="AIServicesItemText">{t("AIText37") as string}</li>
            </ul>
            <img src={process.env.PUBLIC_URL + "BotUA3.webp"} alt="" className="AIImage" />
          </div>
        )}
      </div>
    </div>
  );
};

export default AIServices;
