import { useState } from "react";
import { useMask } from "@react-input/mask";
import "../styles/Modal.scss";
import { sendMessage } from "../telegram";
import { useTranslation } from "react-i18next";

function Modal({ closeModal }: any) {
  const { t } = useTranslation();
  const inputRef = useMask({ mask: "___(__)-___-__-__", replacement: { _: /\d/ } });
  const toggleModal = () => {
    closeModal();
  };

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    text: "",
    email: "",
  });

  const handleChange = (e: any) => {
    const { name, value, files, type } = e.target;
    if (name === "file" && files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: files[0],
      });
    } else if (type === "radio") {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (formValues.name && formValues.phone && formValues.text && formValues.email) {
        let cleanedPhoneNumber = "+" + formValues.phone.replace(/[^\d]/g, "");
        const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${cleanedPhoneNumber}  %0A- Email: ${formValues.email} %0A- Текст: ${formValues.text}`;

        await sendMessage(message);
        console.log(message);
      } else {
        console.log("ERROR");
      }
    } catch (e) {
      console.log("Error");
    } finally {
      closeModal();
    }
  };

  return (
    <div className="Modal">
      <div className="ModalOverlay" onClick={toggleModal}></div>
      <div className="ModalBody">
        <div className="ModalHeader">
          <button onClick={toggleModal}>
            <img src={process.env.PUBLIC_URL + "cross.svg"} alt="" />
          </button>
        </div>
        <div className="ModalContent">
          <h2>{t("ModalTitle") as string}</h2>
          <form className="ModalForm" onSubmit={handleSubmit}>
            <input
              className="ModalNameInput"
              id="name"
              placeholder={t("ModalText1") as string}
              type="text"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              required
            />
            <input
              className="ModalNameInput"
              id="email"
              placeholder={t("ModalText2") as string}
              type="text"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              required
            />
            <input
              className="ModalNameInput"
              id="phone"
              placeholder={t("ModalText3") as string}
              ref={inputRef}
              type="text"
              name="phone"
              value={formValues.phone}
              onChange={handleChange}
              required
            />
            <textarea
              className="ModalNameTextarea"
              id="text"
              placeholder={t("ModalText4") as string}
              name="text"
              value={formValues.text}
              onChange={handleChange}
              required
            />
            <button type="submit" className="ModalButton">
              <div className="points_wrapper">
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
              </div>
              <span className="inner">
                <svg
                  className="icon"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2.5"
                >
                  <polyline points="13.18 1.37 13.18 9.64 21.45 9.64 10.82 22.63 10.82 14.36 2.55 14.36 13.18 1.37"></polyline>
                </svg>
                {t("ModalButton") as string}
              </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Modal;
