import { useTranslation } from "react-i18next";
import "../styles/Marketing.scss";

const Marketing = () => {
  const { t } = useTranslation();

  return (
    <div className="Marketing">
      <h2 className="MarketingServicesTitle">{t("MarketingTitle") as string}</h2>
      <div className="MarketingContent">
        <div className="MarketingImageContent">
          <div className="MarketingData">
            <h3>
              <span>ABC DIGITAL</span> {t("MarketingName") as string}
            </h3>
            <p>{t("MarketingDescription") as string}</p>
          </div>
          <img src={process.env.PUBLIC_URL + "MarketingUA.webp"} alt="" className="MarketingImage" />
        </div>
      </div>
    </div>
  );
};

export default Marketing;
