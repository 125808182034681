import { useTranslation } from "react-i18next";
import "../styles/TimeLine.scss";

const TimeLine = () => {
  const { t } = useTranslation();

  return (
    <div className="TimeLine">
      <h2 className="TimeLineTitle">{t("TLTitle") as string}</h2>
      <div className="TimeLineBody">
        <div className="TimeLineBodyColumn">
          <div className="TimeLineBodyColumnItem">
            <div className="TimeLineBodyColumnItemContent">
              <h4>{t("TLName1") as string}</h4>
              <p>{t("TLDescription1") as string}</p>
              <ul>
                <li>{t("TLList1") as string}</li>
                <li>{t("TLList2") as string}</li>
                <li>{t("TLList3") as string}</li>
              </ul>
            </div>
            <img src={process.env.PUBLIC_URL + "TLUA1.webp"} alt="" className="TimeLineBodyColumnImage" />
          </div>
          <div className="TimeLineBodyColumnItem">
            <div className="TimeLineBodyColumnItemContent">
              <h4>{t("TLName2") as string}</h4>
              <p>{t("TLDescription2") as string}</p>
              <ul>
                <li>{t("TLList4") as string}</li>
                <li>{t("TLList5") as string}</li>
                <li>{t("TLList6") as string}</li>
              </ul>
            </div>
            <img src={process.env.PUBLIC_URL + "TLUA2.webp"} alt="" className="TimeLineBodyColumnImage" />
          </div>
        </div>
        <div className="TimeLineBodyItem">
          <div className="TimeLineBodyItemContent">
            <h4>{t("TLName3") as string}</h4>
            <p>{t("TLDescription3") as string}</p>
            <ul>
              <li>{t("TLList7") as string}</li>
              <li>{t("TLList8") as string}</li>
              <li>{t("TLList9") as string}</li>
            </ul>
          </div>
          <img src={process.env.PUBLIC_URL + "TLUA3.webp"} alt="" className="TimeLineBodyImage" />
        </div>
      </div>
    </div>
  );
};

export default TimeLine;
