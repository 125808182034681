import { useEffect, useState } from "react";
import "./App.scss";
import AIServices from "./components/AIServices";
import Footer from "./components/Footer";
import Main from "./components/Main";
import Marketing from "./components/Marketing";
import Portfolio from "./components/Portfolio";
import PreLoader from "./components/Preloader";
import Services from "./components/Services";
import TimeLine from "./components/TimeLine";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);

  return (
    <div className="App">
      {isLoading && (
        <div className="PreloderBody">
          <div className="PreloaderLogoBody">
            <div className="PreloaderLogo">
              <PreLoader></PreLoader>
            </div>
          </div>
          <div className="Preloader"></div>
        </div>
      )}
      <Main></Main>
      <AIServices></AIServices>
      <Marketing></Marketing>
      <Services></Services>
      <Portfolio></Portfolio>
      <TimeLine></TimeLine>
      <Footer></Footer>
    </div>
  );
}

export default App;
