import { useTranslation } from "react-i18next";
import i18n from "../i18n";

import Spline from "@splinetool/react-spline";
import "../styles/Main.scss";
import { useEffect, useState } from "react";
import WhatsAppIcon from "../icons/WhatsAppIcon";
import TelegramIcon from "../icons/TelegramIcon";
import ViberIcon from "../icons/ViberIcon";
import Modal from "./Modal";

const Main = () => {
  const { t } = useTranslation();
  const [showBurger, setShowBurger] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1250) {
      setShowBurger(false);
      setShowButton(false);
    } else if (width <= 1250 && width > 625) {
      setShowBurger(true);
      setShowButton(false);
    } else if (width <= 625) {
      setShowBurger(true);
      setShowButton(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToServices = () => {
    const aboutElement = document.getElementById("services") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToPortfolio = () => {
    const aboutElement = document.getElementById("portfolio") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToContacts = () => {
    const aboutElement = document.getElementById("contacts") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };

  const changeLanguage = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("ua");
    } else {
      i18n.changeLanguage("en");
    }
  };

  return (
    <div className="Main" id="home">
      {showModal && <Modal closeModal={closeModal}></Modal>}
      <div className="SplineBackground">
        <Spline scene="https://prod.spline.design/ZrMbNQhTdFT4oTTt/scene.splinecode" />
      </div>
      <div className="MainBody">
        <div className="MainHeader">
          <img src={process.env.PUBLIC_URL + "logo.svg"} alt="" className="Logo" />
          {!showBurger && (
            <div className="MainHeaderNav">
              <button onClick={scrollToServices}>{t("headerNav2") as string}</button>
              <button onClick={scrollToPortfolio}>{t("headerNav3") as string}</button>
              <button onClick={scrollToContacts}>{t("headerNav4") as string}</button>
            </div>
          )}
          <div className="Addict_Header">
            <button
              type="button"
              className="button"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <div className="points_wrapper">
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
              </div>
              <span className="inner">
                <svg
                  className="icon"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2.5"
                >
                  <polyline points="13.18 1.37 13.18 9.64 21.45 9.64 10.82 22.63 10.82 14.36 2.55 14.36 13.18 1.37"></polyline>
                </svg>
                {t("headerBtn") as string}
              </span>
            </button>

            <button className="LanguageButton" onClick={changeLanguage}>
              <img src={process.env.PUBLIC_URL + "language-svgrepo-com.svg"} alt="" className="Logo" />
            </button>
            {showBurger && (
              <label
                className="hamburger"
                onClick={() => {
                  setShowSidebar(true);
                }}
              >
                <input type="checkbox" />
                <svg viewBox="0 0 32 32">
                  <path
                    className="line line-top-bottom"
                    d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                  ></path>
                  <path className="line" d="M7 16 27 16"></path>
                </svg>
              </label>
            )}
          </div>
        </div>
        <div className="MainContent">
          <p className="MainSmallTitle">{t("mainSmallTitle") as string}</p>
          <h1 className="MainTitle">{t("MainTitle") as string}</h1>
          <div className="MainButtons">
            <button
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img src={process.env.PUBLIC_URL + "smartphone.svg"} alt="" className="Logo" />
              <p>{t("MainBtn1") as string}</p>
            </button>
            <button
              className="SmallButton"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img src={process.env.PUBLIC_URL + "ai.svg"} alt="" className="Logo" />
            </button>
            <button
              onClick={() => {
                setShowModal(true);
              }}
            >
              <img src={process.env.PUBLIC_URL + "online-store.svg"} alt="" className="Logo" />
              <p>{t("MainBtn2") as string}</p>
            </button>
          </div>
          {showButton && (
            <button
              type="button"
              className="MobileButton"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <div className="points_wrapper">
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
                <i className="point"></i>
              </div>
              <span className="inner">
                <svg
                  className="icon"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2.5"
                >
                  <polyline points="13.18 1.37 13.18 9.64 21.45 9.64 10.82 22.63 10.82 14.36 2.55 14.36 13.18 1.37"></polyline>
                </svg>
                {t("headerBtn") as string}
              </span>
            </button>
          )}
        </div>
      </div>
      {showSidebar && (
        <div className="SidebarMain">
          <div
            className="SidebarOverlay"
            onClick={() => {
              setShowSidebar(false);
            }}
          ></div>
          <div className="SidebarBody">
            <div className="SidebarHeader">
              <button
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <img src={process.env.PUBLIC_URL + "cross.svg"} alt="" />
              </button>
            </div>
            <div className="SidebarNavBody">
              <div className="SidebarNav">
                <p>.01/</p>
                <h1
                  onClick={() => {
                    setShowSidebar(false);
                    scrollToServices();
                  }}
                >
                  {t("headerNav2") as string}
                </h1>
              </div>
              <div className="SidebarNav">
                <p>.02/</p>
                <h1
                  onClick={() => {
                    setShowSidebar(false);
                    scrollToPortfolio();
                  }}
                >
                  {t("headerNav3") as string}
                </h1>
              </div>
              <div className="SidebarNav">
                <p>.03/</p>
                <h1
                  onClick={() => {
                    setShowSidebar(false);
                    scrollToContacts();
                  }}
                >
                  {t("headerNav4") as string}
                </h1>
              </div>
            </div>
            <div className="SidebarLine"></div>
            <div className="SidebarInfo">
              <h2>{t("FooterPhone") as string}</h2>
              <p>
                <a href="tel:+380673694499">+380 67 369 44 99</a>
              </p>
              <p>
                <a href="tel:+380993694499">+380 99 369 44 99</a>
              </p>
            </div>
            <div className="SidebarInfo">
              <h2>{t("FooterEmail") as string}</h2>
              <p>
                <a href="mailto:sales@abc-digital.com">sales@abc-digital.com</a>
              </p>
            </div>
            <div className="SidebarInfo">
              <h2>{t("FooterSocials") as string}</h2>
              <div className="SidebarSocials">
                <a href="https://wa.me/+380673694499">
                  <WhatsAppIcon></WhatsAppIcon>
                </a>
                <a href="https://t.me/abcd_expert">
                  <TelegramIcon></TelegramIcon>
                </a>
                <a href="viber://chat?number=%2B380673694499">
                  <ViberIcon></ViberIcon>
                </a>
              </div>
            </div>
            <div className="SidebarInfo">
              <h2>{t("FooterOffice") as string}</h2>
              <p>{t("FooterOfficeItem") as string}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;
