import { useTranslation } from "react-i18next";
import TelegramIcon from "../icons/TelegramIcon";
import ViberIcon from "../icons/ViberIcon";
import WhatsAppIcon from "../icons/WhatsAppIcon";
import "../styles/Footer.scss";
import Modal from "./Modal";
import { useState } from "react";

const Footer = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const scrollToServices = () => {
    const aboutElement = document.getElementById("services") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToPortfolio = () => {
    const aboutElement = document.getElementById("portfolio") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToHome = () => {
    const aboutElement = document.getElementById("home") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="Footer" id="contacts">
      {showModal && <Modal closeModal={closeModal}></Modal>}
      <div className="FooterBody">
        <img src={process.env.PUBLIC_URL + "logoBlack.png"} alt="" className="LogoBlack" />
        <div className="line"></div>
        <div className="FooterContent">
          <div className="FooterLeft">
            <div className="FooterLeftContacts">
              <p>{t("FooterPhone") as string}</p>
              <h2>
                <a href="tel:+380673694499">+380 67 369 44 99</a>
              </h2>
              <h2>
                <a href="tel:+380993694499">+380 99 369 44 99</a>
              </h2>
              <div className="space"></div>
              <p>{t("FooterEmail") as string}</p>
              <h2>
                <a href="mailto:sales@abc-digital.com">sales@abc-digital.com</a>
              </h2>
            </div>
            <div className="FooterLeftContacts">
              <p>{t("FooterOffice") as string}</p>
              <h2>{t("FooterOfficeItem") as string}</h2>
              <div className="space"></div>
              <p>{t("FooterSocials") as string}</p>
              <div className="Socials">
                <a href="https://wa.me/+380673694499">
                  <WhatsAppIcon></WhatsAppIcon>
                </a>
                <a href="https://t.me/abcd_expert">
                  <TelegramIcon></TelegramIcon>
                </a>
                <a href="viber://chat?number=%2B380673694499">
                  <ViberIcon></ViberIcon>
                </a>
              </div>
            </div>
          </div>
          <div className="FooterRight">
            <button
              onClick={() => {
                setShowModal(true);
              }}
            >
              {t("FooterButton") as string}
            </button>
          </div>
        </div>
        <div className="line"></div>
        <div className="FooterBottom">
          <div className="Copyright">
            <p>© 2025 ABC Digital. {t("FooterCopyright") as string}</p>
          </div>
          <div className="Pages">
            <button onClick={scrollToHome}>{t("headerNav1") as string}</button>
            <p>.</p>
            <button onClick={scrollToServices}>{t("headerNav2") as string}</button>
            <p>.</p>
            <button onClick={scrollToPortfolio}>{t("headerNav3") as string}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
