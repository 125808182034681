import "../styles/Portfolio.scss";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const Portfolio = () => {
  const { t, i18n } = useTranslation();
  const [slideItems, setSlideItems] = useState(4);
  const [spaceBetween, setSpaceBetween] = useState(40);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1440) {
      setSlideItems(4);
      setSpaceBetween(40);
    } else if (width <= 1440 && width > 850) {
      setSlideItems(3);
      setSpaceBetween(40);
    } else if (width <= 850 && width > 625) {
      setSlideItems(2);
      setSpaceBetween(40);
    } else if (width <= 625) {
      setSlideItems(1);
      setSpaceBetween(20);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="Portfolio" id="portfolio">
      <div className="PortfolioImageBG">
        <h2 className="PortfolioTitle">{t("PortfolioTitle") as string}</h2>
        <Swiper
          slidesPerView={slideItems}
          spaceBetween={spaceBetween}
          centeredSlides={true}
          freeMode={true}
          loop={true}
          className="mySwiper"
        >
          {portfolioItems.map((el, index) => (
            <SwiperSlide key={index}>
              <div className="PortfolioContent">
                <a href={el.link} target="_blank" rel="noreferrer">
                  <img src={process.env.PUBLIC_URL + el.image} alt="" className="PortfolioImage" />
                </a>
                <div className="PortfolioText">
                  <h4>{i18n.language === "ua" ? el.nameUA : el.nameEN}</h4>
                  <div className="PortfolioType">
                    <p>{i18n.language === "ua" ? el.typeUA : el.typeEN}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Portfolio;

const portfolioItems = [
  {
    id: 1,
    nameUA: "OsmAnd",
    typeUA: "Моб. Застосунок",
    nameEN: "OsmAnd",
    typeEN: "Mobile App",
    image: "/portfolio/OSMph1.webp",
    link: "https://osmand.net/",
  },
  {
    id: 2,
    nameUA: "BOD.UA",
    typeUA: "Інтернет-Магазин",
    nameEN: "BOD.UA",
    typeEN: "Online-Store",
    image: "/portfolio/BODph1.webp",
    link: "https://bod.com.ua/",
  },
  {
    id: 3,
    nameUA: "Dr.Zueva",
    typeUA: "Лендінг",
    nameEN: "Dr.Zueva",
    typeEN: "Landing",
    image: "/portfolio/ZUEVAph2.webp",
    link: "https://drzueva.com/",
  },
  {
    id: 4,
    nameUA: "Буддизайн-21",
    typeUA: "Багатосторінковий сайт",
    nameEN: "Buddesign-21",
    typeEN: "Multipage Website",
    image: "/portfolio/BUDDph1.webp",
    link: "https://buddesign-21.com/",
  },
  {
    id: 5,
    nameUA: "Terra Group",
    typeUA: "Лендінг",
    nameEN: "Terra Group",
    typeEN: "Landing",
    image: "/portfolio/TERRAph2.webp",
    link: "https://terragroup-ukraine.com/",
  },
  {
    id: 6,
    nameUA: "Шацький Край",
    typeUA: "Багатосторінковий сайт",
    nameEN: "Shatsk Region",
    typeEN: "Multipage Website",
    image: "/portfolio/SHTKph1.webp",
    link: "https://shatskregion.in.ua/",
  },
  {
    id: 7,
    nameUA: "Банька Диканька",
    typeUA: "Лендінг",
    nameEN: "Dikanka Bathhouse",
    typeEN: "Landing",
    image: "/portfolio/SPAph1.webp",
    link: "https://banka-dikanka.kiev.ua/uk/",
  },
  {
    id: 8,
    nameUA: "ШампурOff",
    typeUA: "Лендінг",
    nameEN: "ShampoorOff",
    typeEN: "Landing",
    image: "/portfolio/SMPph1.webp",
    link: "https://shampuroff.com/",
  },
];
