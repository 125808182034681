import { useTranslation } from "react-i18next";
import "../styles/Services.scss";
import Modal from "./Modal";
import { useState } from "react";

const Services = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && <Modal closeModal={closeModal}></Modal>}

      <div className="Services" id="services">
        <h2 className="ServicesTitle">{t("ServicesTitle") as string}</h2>
        <div className="ServicesBody">
          <div className="ServicesItem">
            <div className="ServicesItemContent">
              <h2>{t("ServicesName1") as string}</h2>
              <p>{t("ServicesDescription1") as string}</p>
              <p
                className="ServiceLink"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                {t("ServicesButton") as string}
              </p>
            </div>

            <img src={process.env.PUBLIC_URL + "ServiceUA1.webp"} alt="" className="ServiceImage" />
          </div>
          <div className="ServicesItem">
            <div className="ServicesItemContent">
              <h2>{t("ServicesName2") as string}</h2>
              <p>{t("ServicesDescription2") as string}</p>
              <p
                className="ServiceLink"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                {t("ServicesButton") as string}
              </p>
            </div>

            <img src={process.env.PUBLIC_URL + "ServiceUA2.webp"} alt="" className="ServiceImage" />
          </div>
          <div className="ServicesItem">
            <div className="ServicesItemContent">
              <h2>{t("ServicesName3") as string}</h2>
              <p>{t("ServicesDescription3") as string}</p>
              <p
                className="ServiceLink"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                {t("ServicesButton") as string}
              </p>
            </div>

            <img src={process.env.PUBLIC_URL + "ServiceUA3.webp"} alt="" className="ServiceImage" />
          </div>
          <div className="ServicesItem">
            <div className="ServicesItemContent">
              <h2>{t("ServicesName4") as string}</h2>
              <p>{t("ServicesDescription4") as string}</p>
              <p
                className="ServiceLink"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                {t("ServicesButton") as string}
              </p>
            </div>

            <img src={process.env.PUBLIC_URL + "ServiceUA4.webp"} alt="" className="ServiceImage" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
