const baseUrl = "https://api.telegram.org/bot7082059517:AAH2ZXRH_1en8mqGp7pgfmCJJJdsleD-5JQ/";

export const sendMessage = async (message: string): Promise<void> => {
  const url: string = `${baseUrl}sendMessage?chat_id=-1002292720567&text=${message}`;

  console.log(message);
  const response: Response = await fetch(url);

  console.log("response", response);
};
